












































































































import { Component, Vue } from 'vue-property-decorator';
import MyNav from '../../components/Navbar.vue';
import axios from 'axios';
import slugify from 'slugify';
import  Api from '../../router/api';
import 'jodit/build/jodit.min.css'
import { JoditEditor } from 'jodit-vue'

@Component({
  components: {
    MyNav,JoditEditor
  }
})
export default class Home extends Vue {

  public list: any = null
  public listUser: any = null
  public loading = false;
  public error = false;
  public edit: any = undefined;
  public boxMsg = '';
  public prime = '';
  public obj: any = this.reset();
  public objLive: any = this.reset();

  public reset() {
    return {    
      _id: null,
      name: '',
      slug: '',
      client: this.$route.params.code,
      bgLogin : '',
      bgLive : '',
      typeLive : null,
      hasPrize: false,
      hasQuestion: false,
      question: '',
      typeValidation : null,
      typeIdioma : null,
      linkExternal : null,
      addInputs : [],
      extraInputs : null,
      validationStrings : null,
      languages : {
        pt:{
          check: false,
          link: '',
          chat: '',
          desc: '',
          btn: '',
        },
        en:{
          check: false,
          link: '',
          chat: '',
          desc: '',
          btn: '',
        },
        es:{
          check: false,
          link: '',
          chat: '',
          desc: '',
          btn: '',
        }
      },
      addInput: null,
      chatLink: null
    }
  }

  public async atualiza(){
    this.list = null
    this.obj = this.reset();
    this.loading = true;
    const obj = await axios.get(process.env.VUE_APP_API+"live-comment/"+this.$route.params.client+"/"+this.$route.params.live)
    .then((d) => {return {error: false, body : d.data}; })    
    if (!obj.error){
      this.list = obj.body
      document.title = `Admin - Lista de Comentarios`;  
    }
    this.loading = false;
  }

  public sortear(){
    const item = this.listUser[Math.floor(Math.random()*this.listUser.length)];
    this.prime = item.email
  }

  public async updateComment(objRow: any,isApproved: boolean, isReject: boolean){
    this.loading = true;
    objRow.isApproved = isApproved;
    objRow.isReject = isReject;
    const obj = await axios.post(process.env.VUE_APP_API+"update-comment/",{
      id: objRow._id,
      isReject: isReject,
      isApproved: isApproved
    })
    .then((d) => {return {error: false, body : d.data}; })    
    this.loading = false;
  }

  public removeImg(type: string){
    if (type == 'bgLogin')
      this.obj.bgLogin = '';
    if (type == 'bgLive')
      this.obj.bgLive = '';  
  }

  public async created(){    
    this.loading = true;
    const objLive =  await axios.get(`${process.env.VUE_APP_API}live-read?client=auren&slug=aurenday2024`).then((d) => {return {error: false, body : d.data}; }).catch((e) => { return {error:true, body:null}})                    
    this.objLive = objLive.body;
    if (this.objLive.hasPrize){
      const obj = await axios.post(process.env.VUE_APP_API+"live-users",{live:this.objLive._id})
      .then((d) => {return {error: false, body : d.data}; })    
      if (!obj.error){
        this.listUser = obj.body.users
      }
    }
    await this.atualiza();
  }


  public showLives(item: any){
    this.$router.push({ name: 'lives', params: { code: item._id } })
  }


  public userObj(item: any){
    this.$router.push({ name: 'users', params: { code: item._id } })
  }

  public editObj(item: any){
    if (item.extraInputs !== null && item.addInputs == undefined){
      item['addInputs'] = []
    }
    this.edit = item;
    this.obj = item;
  }

  public async deleteObj(item: any){
    if (confirm('Deseja excluir esta live')){
      await axios.post(process.env.VUE_APP_API+"live/delete",{_id: item._id}).then((data) => data.data).catch(() => this.error = true);
      await this.atualiza();
      this.loading = false;
    }
  }

  public previewImage(event: any) {
    const input = event.target;    
    this.error = false;
    this.boxMsg = '';
    if (input.files && input.files[0]) {
      if (input.files[0].size/1000 > 2000){
        this.error = true;
        this.boxMsg = 'Tamanho máximo de 2MB';
        return
      }
      const name = event.target.files[0].name;
      const lastDot = name.lastIndexOf('.');
      const ext = name.substring(lastDot + 1);
      if (ext !== 'jpg' && ext !== 'jpeg'){
        this.error = true;
        this.boxMsg = 'Arquivos apenas JPG podem ser enviados';
        return;
      }
        const reader = new FileReader();
        reader.onload = (e) => {
            this.obj.bgLive = e.target!.result!.toString();
        }
        reader.readAsDataURL(input.files[0]);
    }
  }

  public previewImageLogin(event: any) {
    const input = event.target;    
    this.error = false;
    this.boxMsg = '';
    if (input.files && input.files[0]) {
      if (input.files[0].size/1000 > 2000){
        this.error = true;
        this.boxMsg = 'Tamanho máximo de 2MB';
        return
      }
      const name = event.target.files[0].name;
      const lastDot = name.lastIndexOf('.');
      const ext = name.substring(lastDot + 1);
      if (ext !== 'jpg' && ext !== 'jpeg'){
        this.error = true;
        this.boxMsg = 'Arquivos apenas JPG podem ser enviados';
        return;
      }
        const reader = new FileReader();
        reader.onload = (e) => {
            this.obj.bgLogin = e.target!.result!.toString();
        }
        reader.readAsDataURL(input.files[0]);
    }
  }

  public async create(){
        this.error = false
        if (this.obj.name !== '')
        {
            this.loading = true;
            let resp = null;            
            if (this.edit == null){
              resp = await axios.post(process.env.VUE_APP_API+"live/create",this.obj).then((data) => data.data).catch(() => this.error = true);                
              if (resp !== null && resp.uuid !== undefined){
                if (this.list == null) this.list = []
                this.obj._id = resp.uuid
                this.list.push(this.obj)
              }              
            }
            if (this.edit !== null){
              resp = await axios.put(process.env.VUE_APP_API+`live/${this.edit['_id']}`,this.obj).then((data) => data.data).catch(() => this.error = true);              
              if (resp._id !== undefined){
                await this.atualiza();
              }
            }            
            this.loading = false;
        }
        return false;
    }
}
